import React, { Fragment } from "react";
import CarImage from "../../../../assets/images/image12.jpg";
import FourComponentLoader from "../../../Elements/SkeletonLoader/FourComponentLoader";

const HowToGetStarted = ({ data, loaded }) => {
  return (
    <>
      {loaded ? (
        <>
          {data && (
            <div className="relative my-14" 
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex flex-col   items-center md:h-full my-0 md:my-auto  py-14">
                <div className="realtive text-center w-full ">
                  <div className=" bg-[#EEEDFF] text-blue-violet-500 font-semibold text-2xl px-8 py-3 rounded-full inline-block">
                    Get Start
                  </div>
                  <div className="text-black font-semibold text-4xl mt-8">
                    How to{" "}
                    <span className="text-blue-violet-500"> Get Started </span>
                  </div>
                </div>
                <div className="relative flex">
                  <div className="relative w-full">
                    <div className="flex  w-full mt-8 space-x-6">
                      {data &&
                        data?.steps.map((item, index) => {
                          return (
                            <div
                              className="realtive bg-white  shadow-lg  rounded-4xl w-full px-10 py-12 flex flex-col "
                              key={index}
                            >
                              <div className="bg-[#EEEDFF] text-blue-violet-500 text-3xl w-16 h-16 rounded-full flex items-center justify-center font-medium mb-4 ">
                                <i className={item?.icon}></i>
                              </div>
                              <div className=" text-black text-xl font-semibold mb-3">
                                {item?.heading}
                              </div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item?.description,
                                }}
                                className=" text-slate-400 text-base font-normal "
                              >
                                {/* {item?.description} */}
                              </div>
                              <Fragment key={index}></Fragment>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <FourComponentLoader />
        </>
      )}
    </>
  );
};

export default HowToGetStarted;
