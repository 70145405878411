import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import FourComponentLoader from "../../Elements/SkeletonLoader/FourComponentLoader";

const BestRideExperiences = ({ data, loaded }) => {
  useEffect(() => {
    Aos.init(Aos);
  }, []);

  return (
    <>
      {loaded ? (
        <div className="relative bg-blue-violet-50 xl:rounded-b-[340px] 2xl:rounded-b-[440px] xl:mb-28">
          <div className="w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex items-center">
            <div className="realtive flex flex-col w-full">
              <div
                className="bg-white rounded-full px-8 py-3 text-center text-blue-violet-500 font-semibold text-lg md:text-2xl mx-auto xl:mt-28 lg:mt-20 mt-10"
                data-aos="fade-right"
                data-aos-duration="3000"
                dangerouslySetInnerHTML={{ __html: data && data?.title }}
              ></div>
              <div
                className="text-black lg:text-2xl xl:text-4xl mt-4 text-center px-94 leading-snug px-6 lg:px-56 3xl:px-[440px] 2xl:px-72 xl:px-56 font-medium"
                data-aos="fade-left"
                data-aos-duration="3000"
                dangerouslySetInnerHTML={{
                  __html: data && data?.description,
                }}
              ></div>
              <div
                className="flex w-full mb-10 md:mb-16 mt-12"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="flex flex-wrap  mx-auto gap-8 ">
                  {data &&
                    Array.isArray(data.steps) &&
                    data.steps.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Link to={item?.link} className="w-full md:w-48 xl:w-64">
                            <div className="relative bg-white rounded-3xl flex flex-col px-4 py-8  w-full md:w-48 h-full xl:w-64 ride-item transition-all duration-300 ease-in-out">
                              <div className="ride-item-icon w-20 h-20 rounded-full bg-slate-200 flex items-center justify-center outline-dashed outline-2 outline-offset-2 outline-blue-violet-500 text-3xl text-blue-violet-500 mx-auto">
                                <i class={item?.icon}></i>
                              </div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item?.heading,
                                }}
                                className="ride-item-title xl:text-lg lg:text-base font-semibold text-blue-violet-500 mt-5 text-center"
                              ></div>
                            </div>
                          </Link>
                        </React.Fragment>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="m-5">
          <FourComponentLoader />
        </div>
      )}
    </>
  );
};

export default BestRideExperiences;
