import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Button from "../components/form/Button";
import { useSelector } from "react-redux";

const DefaultHeader = ({ data }) => {
  const [menuToggle, setMenuToggle] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const profileData = useSelector((state) => state.siteSettings.data);

  const handleMouseLeave = () => {
    setIsOpen(false);
    setSelectedOption(null);
  };
  return (
    <>
      <header className="sticky z-30 top-0 w-full flex items-center h-24 bg-white shadow">
        <div className="w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex items-center">
          <div className="max-w-[140px] h-16 mr-auto overflow-hidden">
            <Link to={"/"} className="block w-full h-full">
              <img
                className="w-full h-full object-contain"
                src={profileData && profileData?.logo?.url}
              />
            </Link>
          </div>
          <div
            className={classNames(
              "fixed z-10 xl:relative top-0 xl:top-auto left-0 xl:left-auto w-80 xl:w-auto h-full xl:h-auto bg-white xl:bg-transparent flex flex-col xl:flex-row xl:items-center xl:justify-end gap-8 py-5 xl:py-0 shadow-2xl xl:shadow-none xl:!translate-x-0 transition:all duration-300",
              menuToggle ? "translate-x-0" : "-translate-x-full"
            )}
          >
            <div className="flex items-center justify-center pl-4 xl:pl-0 xl:hidden border-b border-slate-200 pb-5">
              <div className="max-w-[140px] h-16 mr-auto overflow-hidden">
                <Link to={"/"} className="block w-full h-full">
                  <img
                    className="w-full h-full object-contain"
                    src={profileData && profileData?.logo?.url}
                  />
                </Link>
              </div>
            </div>
            <ul className="flex flex-col pl-4 xl:pl-0 xl:flex-row gap-4 xl:gap-10 font-medium text-xl text-black uppercase">
              <li className="hover:text-blue-violet-800 transition-all ease-in-out">
                <Link to={"/"}>Home</Link>
              </li>
              {/* <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex items-center w-full hover:text-blue-violet-800 justify-center gap-x-1.5 rounded-md font-semibold text-black uppercase text-xl hover:bg-gray-50 outline-none">
                    Rides <i class="fa-regular fa-angle-down"></i>
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            Healthcare & Paratransit
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            Elite Luxury Concierge 
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                           Electric Ride 
                          </a>
                        )} 
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                          Passenger Ride Share
                          </a>
                        )} 
                      </Menu.Item> 
                    </div>
                  </Menu.Items >
                </Transition>
              </Menu> */}
              <li
                className="hover:text-blue-violet-800 transition-all  duration-300 ease-in-out"
                onMouseLeave={handleMouseLeave}
              >
                <Link onMouseEnter={() => setIsOpen(true)}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      transition:" all 1s ease-out",
                    }}
                  >
                    Ride
                    {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </div>
                </Link>
                {isOpen && (
                  <div
                    className="absolute z-10 mt-0 py-2  bg-white rounded-lg shadow-lg right-0 left-0"
                    onMouseLeave={handleMouseLeave}
                  >
                    <Link
                      to={"/healthcareparatransit"}
                      className="block px-4 py-2 text-gray-800 hover:bg-[#F6F5FF] hover:text-blue-violet-800 transition-all duration-300 ease-in-out text-base capitalize"
                      onMouseEnter={() => setSelectedOption("Option 1")}
                      onClick={handleMouseLeave}
                    >
                      Healthcare & Paratransit
                    </Link>
                    <Link
                      to={"/elitluxuryconcierge"}
                      className="block px-4 py-2 text-gray-800 hover:bg-[#F6F5FF] hover:text-blue-violet-800 transition-all duration-300 ease-in-out text-base capitalize"
                      onMouseEnter={() => setSelectedOption("Option 2")}
                      onClick={handleMouseLeave}
                    >
                      Elit Luxury Concierge
                    </Link>
                    <Link
                      to={"/electricride"}
                      className={`block px-4 py-2 text-gray-800 hover:bg-[#F6F5FF] hover:text-blue-violet-800 transition-all duration-300 ease-in-out text-base capitalize ${
                        selectedOption === "Option 3" ? "bg-gray-200" : ""
                      } hover:bg-gray-200`}
                      onMouseEnter={() => setSelectedOption("Option 3")}
                      onClick={handleMouseLeave}
                    >
                      Electric Ride
                    </Link>
                    <Link
                      to={"/rideshare"}
                      className={`block px-4 py-2 text-gray-800 hover:bg-[#F6F5FF] hover:text-blue-violet-800 transition-all duration-300 ease-in-out text-base capitalize ${
                        selectedOption === "Option 4" ? "bg-gray-200" : ""
                      } hover:bg-gray-200`}
                      onMouseEnter={() => setSelectedOption("Option 4")}
                      onClick={handleMouseLeave}
                    >
                      Passenger Ride Share
                    </Link>
                  </div>
                )}
              </li>

              <li className="hover:text-blue-violet-800 transition-all ease-in-out duration-300">
                <Link to={"/drive"}>Drive</Link>
              </li>
              <li className="hover:text-blue-violet-800 transition-all ease-in-out duration-300">
                <Link to={"/contact"}>Contact</Link>
              </li>
            </ul>
          </div>
          <div className="ml-auto xl:hidden">
            <Button
              buttonClasses={"!bg-carnation-500"}
              buttonHasLink={false}
              buttonFunction={() => setMenuToggle(!menuToggle)}
              buttonIcon={"fa-regular fa-bars"}
              buttonIconPosition={"left"}
            />
          </div>
        </div>
        <div
          className={classNames(
            "fixed z-[9] w-full h-full top-0 left-0 bg-carnation-500/75 transition-all duration-300",
            menuToggle ? "opacity-100 visible" : "opacity-0 invisible"
          )}
          onClick={() => setMenuToggle(!menuToggle)}
        ></div>
        {/* <Modal isOpen={isOpen} setOpen={setOpen} /> */}
      </header>
    </>
  );
};

export default DefaultHeader;
