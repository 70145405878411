import React from "react";
import CarImage from "../../../../assets/images/image14.png";
import CarImageAlt from "../../../../assets/images/image15.png";
import ParatransitCar from "../../../../assets/images/image16.png";
import SpecializedCar from "../../../../assets/images/image17.png";
import { Link } from "react-router-dom";
import { classNames } from "../../../../helpers/classNames";
import TwoSectionLoader from "../../../Elements/SkeletonLoader/TwoSectionLoader";

const Service = ({ data, loaded }) => {
  return (
    <>
      {loaded ? (
        <div className="relative pt:pt-8 md:pt-28">
          <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex flex-col-reverse  md:flex-row items-center md:h-full my-0 md:my-auto ">
            <div className="relative">
              <div
                dangerouslySetInnerHTML={{ __html: data?.title }}
                className="text-black text-4xl font-bold text-center mb-12"
              >
                {/* Our<span className="text-blue-violet-500 italic">Services</span> */}
              </div>

              <div className="relative space-y-14">
                {data &&
                  data?.steps.map((item, index) => (
                    <div
                      key={item._id}
                      className={classNames(
                        "relative flex flex-row items-center justify-between gap-14",
                        index % 2 === 1 ? "flex-row-reverse" : ""
                      )}
                    >
                      <div className="relative w-1/2"
                      data-aos="fade-right"
                      data-aos-duration="3000"
                      >
                        <div className="w-full aspect-[2/1.2] overflow-hidden rounded-4xl">
                          <img
                            src={item?.stepsImage?.url}
                            alt=""
                            className="w-full h-full object-cover"
                          />
                        </div>
                      </div>
                      <div className="relative w-1/2 ">
                        <div
                          dangerouslySetInnerHTML={{ __html: item?.heading }}
                          className=" text-4xl font-bold flex flex-col md:leading-tight"
                        >
                          {/* {item.heading}
                      <span className=" !text-blue-violet-500 italic">
                        {item.sub}
                      </span> */}
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item?.description,
                          }}
                          className="text-base leading-7 mt-4 text-slate-600"
                        >
                          {/* {item.description} */}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="border w-[100%]">
          {" "}
          <TwoSectionLoader />
        </div>
      )}
    </>
  );
};

export default Service;
