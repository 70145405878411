import React, { useEffect, useState } from "react";
import DriveBanner from "../components/sections/Drive/DriveBanner/DriveBanner";
import DriveComponent from "../components/sections/Drive/JoinTeam/DriveComponent";
import DrivesRequirements from "../components/sections/Drive/DriversRequerments/DrivesRequirements";
import HowToGetStarted from "../components/sections/Drive/HowToGetStarted/HowToGetStarted";
import DriveBenefits from "../components/sections/Drive/DriveBenefits/DriveBenefits";
import DownloadApp from "../components/sections/DownloadApp/DownloadApp";
import axios from "axios";
import BASE_URL from "../config/host";

const Drive = () => {
  const [allPageData, setAllPageData] = useState(null);
  const [loaded, setLoaded] = useState(true);

  let fetchPages = async () => {
    setLoaded(false);
    try {
      let data = await axios.get(`${BASE_URL}/pages`, {
        params: {
          limit: 100,
        },
      });
      if (data.status === 200) {
        setAllPageData(data?.data?.docs);
        setLoaded(true);
      }
    } catch (error) {
      setLoaded(true);
      console.log(error, "page error");
    }
  };
  useEffect(() => {
    fetchPages();
  }, []);

  return (
    <>
      <DriveBanner
        data={allPageData && allPageData.find((elm) => elm?.type === "drive1")}
        loaded={loaded}
      />
      <DriveComponent
        data={allPageData && allPageData.find((elm) => elm?.type === "drive2")}
        loaded={loaded}
      />
      <DrivesRequirements
        data={allPageData && allPageData.find((elm) => elm?.type === "drive3")}
        loaded={loaded}
      />
      <HowToGetStarted
        data={allPageData && allPageData.find((elm) => elm?.type === "drive4")}
        loaded={loaded}
      />
      <DriveBenefits
        data={allPageData && allPageData.find((elm) => elm?.type === "drive5")}
        loaded={loaded}
      />
      <DownloadApp
        data={allPageData && allPageData.find((elm) => elm?.type === "home4")}
        loaded={loaded}
      />
    </>
  );
};

export default Drive;
