import React, { useEffect, useState } from "react";
import LuxurySedans from "../assets/images/image19.jpg";
import SUVs from "../assets/images/image20.jpg";
import Vans from "../assets/images/image22.jpg";
import Coaches from "../assets/images/image21.jpg";
import ElitLuxuryConciergeBanner from "../components/sections/ElitLuxuryConcierge/ElitLuxuryConciergeBanner";
import Fleet from "../components/sections/ElitLuxuryConcierge/Fleet";
import OurServices from "../components/sections/ElitLuxuryConcierge/OurServices";

import Airport from "../assets/images/image23.jpg";
import Corporate from "../assets/images/image24.jpg";
import Occasions from "../assets/images/image26.jpg";
import VIP from "../assets/images/image27.jpg";
import LuxuryTransportation from "../components/sections/ElitLuxuryConcierge/LuxuryTransportation";
import { useSelector } from "react-redux";
import ContactUs from "../components/sections/Common/ContactUs/ContactUs";
import BookCar from "../components/sections/Common/BookCar/BookCar";
import axios from "axios";
import BASE_URL from "../config/host";

const ElitLuxuryConcierge = () => {
  const [allPageData, setAllPageData] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const siteSettingData = useSelector((state) => state?.siteSettings?.data);
  const OurFleet = [
    {
      _id: 1,
      image: LuxurySedans,
      title: "Luxury Sedans",
      description:
        " Travel in elegance and refinement with our fleet of luxury sedans. Featuring plush interiors, advanced amenities, and state of-the-art technology, our sedans are perfect for executive travel and special occasions.",
    },
    {
      _id: 2,
      image: SUVs,
      title: "Executive SUVs",
      description:
        " For larger groups or those requiring extra space and comfort, our executive SUVs offer ample seating and luggage capacity, ensuring a luxurious and comfortable travel experience..",
    },
    {
      _id: 3,
      image: Vans,
      title: "Luxury Vans",
      description:
        "  Ideal for group travel or special events, our luxury vans  provide spacious and sophisticated transportation solutions, complete  with premium amenities and customizable seating arrangements.",
    },
    {
      _id: 4,
      image: Coaches,
      title: "Luxury Coaches",
      description:
        " Experience the ultimate in luxury and comfort with our  fleet of luxury coaches. Perfect for corporate events, weddings, and  group travel, our coaches offer first-class amenities and unparalleled  ●  comfort for larger groups.",
    },
  ];

  const OurService = [
    {
      _id: 1,
      image: Airport,
      heading: "Airport",
      headingAlt: "Transfers",
      description:
        "Arrive in style and comfort with our luxury airport ntransfer services. Our professional chauffeurs will greet you upon arrival and ensure a seamless transition to your destination.",
    },
    {
      _id: 2,
      image: Corporate,
      heading: " Corporate ",
      headingAlt: "Travel",
      description:
        "Impress clients and colleagues alike with our executive transportation services. Whether you're attending meetings, conferences, or corporate events, our luxury vehicles will enhance your professional image.",
    },
    {
      _id: 3,
      image: Occasions,
      heading: "Special",
      headingAlt: "Occasions",
      description:
        "Make your special day unforgettable with our luxury transportation services for weddings, anniversaries, and other milestone events. Our experienced chauffeurs and impeccably maintained vehicles will add a touch of elegance to your celebration.",
    },
    {
      _id: 4,
      image: VIP,
      heading: "VIP",
      headingAlt: "Tours",
      description:
        "Explore the sights and attractions of your destination in style with our VIP tour services. From city tours to wine country excursions,  our knowledgeable chauffeurs will guide you on a memorable journey filled with luxury and sophistication",
    },
  ];

  const GetStart = [
    {
      _id: 1,
      icon: "fa-solid fa-ship",
      title: "Exquisite Fleet",
      description:
        "Our fleet consists of the finest luxury vehicles, meticulously maintained to ensure optimal comfort, safety, and style.",
    },
    {
      _id: 2,
      icon: "fa-solid fa-user-pilot-tie",
      title: "Professional Chauffeurs",
      description:
        "Our chauffeurs are highly trained professionals with years of experience in the luxury transportation industry. Impeccably dressed and courteous, they are dedicated to providing you with a first-class experience.",
    },
    {
      _id: 3,
      icon: "fa-solid fa-users",
      title: "Personalized Service",
      description:
        "At Grand, we believe in the power of personalized service. From customizing your itinerary to accommodating special requests, our team is committed to exceeding your expectations and ensuring your satisfaction",
    },
    {
      _id: 4,
      icon: "fa-solid fa-clock-one",
      title: "24/7 Availability",
      description:
        "Whether you're traveling early in the morning or late at night, our luxury transportation services are available around the clock to meet your needs and accommodate your schedule",
    },
  ];

  const BookCarDetails = {
    title: "How To",
    titleAlt: "Book",
    subtitle: "Booking your elite luxury transportation with Grand is simple",

    subdata: [
      {
        _id: 1,
        icon: "fa-light fa-globe",
        heading: "Online Reservation",
        description:
          "Visit our website to book your  transportation conveniently online. Simply select your desired vehicle, enter your details, and we'll take care of the rest.",
      },
      {
        _id: 2,
        icon: "fa-solid fa-phone",
        heading: "Phone Reservation",
        description:
          "Prefer to speak with a representative? Give us a call, and our friendly staff will assist you in scheduling your transportation needs.",
      },
      {
        _id: 3,
        icon: "fa-solid fa-mobile",
        heading: " Mobile App",
        description:
          " Download our mobile app for quick and hassle-free  booking on the go. Manage your reservations, track your ride, and  receive real-time updates with ease.",
      },
    ],
  };

  const contactDeatail = {
    title: "Contact Us",
    subTitle:
      " Ready to experience the height of luxury and sophistication with Grand Elite Luxury Transportation? Contact us today to book your next journey.",
    btn: "Contact Us",
    heading:
      " Experience the epitome of luxury travel with Grand Elite Luxury Transportation. Let us redefine your expectations and elevate your journey to new heights of comfort and style",
    icon1: "fa-solid fa-phone",
    number: "718-433-4255",
    icon2: "fa-solid fa-envelope",
    email: "luxury@rydegrand.com",
    icon3: "fa-solid fa-location-dot",
    address: "36-13 32nd St LIC NY 11106",
  };

  let fetchPages = async () => {
    setLoaded(false);
    try {
      let data = await axios.get(`${BASE_URL}/pages`, {
        params: {
          limit: 100,
        },
      });
      if (data.status === 200) {
        setAllPageData(data?.data?.docs);
        setLoaded(true);
      }
    } catch (error) {
      setLoaded(true);
      console.log(error, "page error");
    }
  };
  useEffect(() => {
    fetchPages();
  }, []);

  const serviceData =
    allPageData && allPageData.length > 0
      ? allPageData.filter(
          (elm) =>
            elm?.type === "elit-luxury-concierge3" ||
            elm?.type === "elit-luxury-concierge4" ||
            elm?.type === "elit-luxury-concierge5" ||
            elm?.type === "elit-luxury-concierge6"
        )
      : [];

  return (
    <>
      <ElitLuxuryConciergeBanner
        data={
          allPageData &&
          allPageData.find((elm) => elm?.type === "elit-luxury-concierge1")
        }
        loaded={loaded}
      />
      <Fleet
        // data={OurFleet}
        data={
          allPageData &&
          allPageData.find((elm) => elm?.type === "elit-luxury-concierge2")
        }
        loaded={loaded}
      />
      <OurServices
        // data={OurService}
        data={
          allPageData &&
          allPageData.find((elm) => elm?.type === "elit-luxury-concierge3")
        }
        loaded={loaded}
      />
      <LuxuryTransportation data={GetStart} />
      <BookCar
        // data={BookCarDetails}
        data={
          allPageData &&
          allPageData.find((elm) => elm?.type === "healthcare-and-paratransit4")
        }
        loaded={loaded}
      />
      <ContactUs
        data={contactDeatail}
        data1={siteSettingData}
        loaded={loaded}
      />
    </>
  );
};

export default ElitLuxuryConcierge;
