import React, { useEffect } from "react";
import Button from "../../../form/Button";
import AccordionComponent from "../../AccordionComponent";
import TwoSectionLoader from "../../../Elements/SkeletonLoader/TwoSectionLoader";
import Image from "../../../../assets/images/image8.jpg";
import Aos from "aos";
import "aos/dist/aos.css";

const DriveComponent = ({ data, loaded }) => {
  useEffect(() => {
    Aos.init(Aos);
  }, []);
  return (
    <>
      {loaded ? (
        <div className="w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex items-center xl:pt-28 lg:pt-20 pt-24 py-14">
          <div className="flex flex-col xl:flex-row items-center w-full xl:gap-10 rounded-7xl">
            <div
              className="relative xl:w-2/4 w-full flex flex-col justify-center bg-black rounded-4xl px-12  md:px-24  "
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className="relative w-full">
                <div className=" w-full overflow-hidden mx-auto -mt-14 xl:-mt-28 mb-8 md:mb-16">
                  <img
                    src={(data && data?.image?.url) || Image}
                    className="w-full h-full object-contain"
                  />
                </div>
                <Button
                  buttonClasses={"!px-5 bg-transparent !mb-10 !rounded-full"}
                  buttonHasLink={true}
                  buttonLink={data && data?.buttonLink1}
                  buttonLabel={data && data?.buttonText1}
                  buttonLabelClasses={"text-base"}
                  dangerouslySetInnerHTML={{ __html: data && data?.title }}
                  buttonEffect={"filled"}
                  buttonIconPosition={"right"}
                  buttonIcon={"fa-solid fa-angle-right"}
                />
              </div>
            </div>
            <div
              className="flex flex-col relative xl:w-2/4 xl:mt-0 lg:mt-12 mt-8"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div className="relative w-24 md:w-36 bg-blue-violet-100 rounded-full flex items-center justify-center py-2 md:py-3 text-blue-violet-500 font-semibold xl:text-2xl ml-0">
                Drive
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: data && data?.title }}
                className="text-black 2xl:text-4xl md:text-3xl text-xl mt-3 md:mt-6 text-left px-94 leading-snug 2xl:pr-30 lg:pr-80 3xl:pr-36 font-semibold mb-4 md:mb-8"
              ></div>
              <AccordionComponent data={data && data?.steps} />
              {data && data?.buttonLink2 && (
                <div className="relative">
                  <Button
                    buttonClasses={
                      "!px-5 !relative w-40 py-8 !rounded-full text-xl font-medium"
                    }
                    buttonHasLink={true}
                    buttonLink={data && data?.buttonLink2}
                    buttonLabel={data && data?.buttonText2}
                    // buttonLink={data.actionLink}
                    // buttonLabel={"Explore More"}
                    buttonLabelClasses={"text-base"}
                    buttonEffect={"filled"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          <TwoSectionLoader />
        </>
      )}
    </>
  );
};

export default DriveComponent;
