import React, { useEffect, useState } from "react";
import HomeBanner from "../components/sections/Home/HomeBanner";
import BestRideExperiences from "../components/sections/Ride/BestRideExperiences";
import DriveComponent from "../components/sections/Drive/JoinTeam/DriveComponent";
import Mobile from "../assets/images/image8.jpg";
import SignInProcess from "../components/sections/SignInProcess/SignInProcess";
import DownloadApp from "../components/sections/DownloadApp/DownloadApp";
import axios from "axios";
import BASE_URL from "../config/host";
const Home = () => {
  const [allPageData, setAllPageData] = useState(null);
  const [loaded, setLoaded] = useState(true);

  let fetchPages = async () => {
    setLoaded(false);
    try {
      let data = await axios.get(`${BASE_URL}/pages`);

      if (data.status === 200) {
        setAllPageData(data?.data?.docs);
        setLoaded(true);
      }
    } catch (error) {
      setLoaded(true);
      console.log(error, "page error");
    }
  };
  useEffect(() => {
    fetchPages();
  }, []);

  return (
    <>
      <HomeBanner
        data={allPageData && allPageData.find((elm) => elm?.type === "home1")}
        loaded={loaded}
      />
      <BestRideExperiences
        data={allPageData && allPageData.find((elm) => elm?.type === "home2")}
        loaded={loaded}
      />
      <DriveComponent
        data={allPageData && allPageData.find((elm) => elm?.type === "home3")}
        loaded={loaded}
      />
      <DownloadApp
        data={allPageData && allPageData.find((elm) => elm?.type === "home4")}
        loaded={loaded}
      />
      <SignInProcess
        data={allPageData && allPageData.find((elm) => elm?.type === "home5")}
        loaded={loaded}
      />
    </>
  );
};

export default Home;
