import React from "react";
import Image from "../../assets/images/image4.jpg";
import Image2 from "../../assets/images/image5.jpg";
import { Link } from "react-router-dom";

const AppCallToAction = ({ data }) => {
  return (
    <>
      <div className="flex items-center gap-3">
        <div className="bg-white rounded-full px-5 py-2 cursor-pointer shadow-lg">
          <div className="w-[70px] md:w-[100px] overflow-hidden">
            {/* <img src={Image} className="w-full h-full object-contain"/> */}
            <Link to={data && data?.buttonLink1} target="blank">
              {/* {data && data?.buttonText1} */}
              <img src={Image} className="w-full h-full object-contain" />
            </Link>
          </div>
        </div>
        <div className="bg-white rounded-full px-5 py-2 cursor-pointer  shadow-lg">
          <div className="w-[70px] md:w-[100px] overflow-hidden text-center">
            {/* <img src={Image2} className="w-full h-full object-contain"/> */}
            <Link to={data && data?.buttonLink2} target="blank">
              {/* {data && data?.buttonText2} */}
              <img src={Image2} className="w-full h-full object-contain" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppCallToAction;
