import React from 'react';
import {Outlet} from "react-router-dom";
import DefaultHeader from '../Shared/DefaultHeader';
import logo from '../assets/images/logo.jpeg';
import DefaultFooter from "../Shared/DefaultFooter";


 const DefaultLayout = () => {

    const menuData = {
        logo: logo,
        menu: [
            {
                _id:1,
                name:"Home",
                link:"/home",
                isParent: false,
            },
            {
                _id:2,
                name:"Ride",
                link:"/ride",
                isParent: true,
            },
            {
                _id:3,
                name:"Drive",
                link:"/drive",
                isParent: false,
            },
            {
                _id:3,
                name:"Contact",
                link:"/contact",
                isParent: false,
            },
        ]
    }

    const footerData = {   
      subTitle:"Join our team of drivers and start ",
      AltSubTile:"earning with Grand",
      actionLink: "/",
      buttonTitle:"Download This App ",
      actionLabel:"Download This App ",
    }

  return (
    <>
    <DefaultHeader data={menuData} /> 
    <Outlet />
    <DefaultFooter data={footerData}/>
   
    
    </>
    
  )
}

export default DefaultLayout
