import React, { useRef, useState } from "react";
import MobileImage from "../../../assets/images/image9.jpg";
import CercleImage from "../../../assets/images/image10.png";
import TwoSectionLoader from "../../Elements/SkeletonLoader/TwoSectionLoader";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

const SignInProcess = ({ data, loaded }) => {
  return (
    <>
      {loaded ? (
        <>
          {" "}
          <div className="w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex items-center lg:py-14 xl:py-28 py-10">
            <div className="relative">
              <div className="relative flex  flex-col-reverse xl:flex-row gap-8 md:gap-14 w-full">
                <div
                  className="relative w-3/5"
                  data-aos="fade-right"
                  data-aos-duration="3000"
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: data && data?.title }}
                    className=" text-block mb-4 md:mb-10 font-Lexend  sm:text-4xl xl:text-5xl text-2xl font-bold text-black leading-tight"
                  >
                    {/* {data.title}{" "}
              <span className="text-blue-violet-500 italic">
                {data.titleAlt}
              </span>{" "} */}
                  </div>
                  {data &&
                    Array.isArray(data?.steps) &&
                    data?.steps.length > 0 &&
                    data.steps.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className="flex items-start gap-3 lg:gap-6 xl:gap-8 w-full mb-12">
                            <div className="w-6 h-6 lg:w-14  xl:w-20 lg:h-14 xl:h-20 flex items-center justify-center lg:text-3xl xl:text-4xl font-bold rounded-full outline-dashed outline-slate-300 flex-shrink-0">
                              {index + 1}
                            </div>
                            <div className="flex w-full flex-col flex-shrink">
                              <div className="xl:text-xl md:text-lg font-medium mb-1 text-black">
                                {item?.heading}
                              </div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item?.description,
                                }}
                                className="xl:text-lg md:text-base font-normal leading-7   text-slate-400"
                              ></div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                </div>
                <div
                  className="relative w-2/5 mx-auto signin-img-content lg:h-[870px]"
                  data-aos="fade-left"
                  data-aos-duration="3000"
                >

                  <div className="relative h-full flex w-full">

                    <Swiper spaceBetween={10}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Pagination]}
                      className="mySwiper">
                      <SwiperSlide>
                        <div className="lg:w-full xl:w-fit overflow-hidden relative z-10">
                          <img
                            // src={MobileImage}
                            src={data && data?.image?.url}
                            className="w-full h-full object-contain"
                          ></img>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="lg:w-full xl:w-fit overflow-hidden relative z-10">
                          <img
                            // src={MobileImage}
                            src={data && data?.image?.url}
                            className="w-full h-full object-contain"
                          ></img>
                        </div>
                      </SwiperSlide>
                  
                    </Swiper>

                    <div className="absolute right-0 z-0 lg:-translate-x-[50%] xl:translate-x-[0%] top-2/4  -translate-y-1/2 lg:w-[300px] lg:h-[300px] xl:w-[400px] xl:h-[400px] overflow-hidden ">
                      <img
                        src={CercleImage}
                        className="w-full h-full object-contain"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="m-5">
          <TwoSectionLoader />
        </div>
      )}
    </>
  );
};
export default SignInProcess;
