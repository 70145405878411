import React, { useEffect } from "react";
import SliderImage from "../../../assets/images/background-image.png";
import Aos from "aos";
import "aos/dist/aos.css";
import AppCallToAction from "../AppCallToAction";
import BannerLoader from "../../Elements/SkeletonLoader/BannerLoader";

const RideShareBanner = ({ data, loaded }) => {
  useEffect(() => {
    Aos.init(Aos);
  }, []);
  return (
    <>
      {loaded ? (
        <div className="relative overflow-hidden w-full z-0 h-[600px] md:h-[500px] xl:h-[650px]  flex items-center">
          <>
            <div className="relative  overflow-hidden w-full h-full ">
              <div
                className="absolute right-0 top-0"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <div className="xl:w-[1000px] lg:w-[580px] md:w-[500px] h-full overflow-hidden xl:h-[650px] pt-10">
                  <img
                    src={data && data?.image?.url}
                    className="w-full h-full object-fill"
                  />
                </div>
              </div>
              <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex flex-col-reverse  md:flex-row items-center md:h-full my-0 md:my-auto">
                <div
                  className="relative w-1/2 md:pr-16 mt-4 md:mt-0"
                  data-aos="fade-right"
                  data-aos-duration="3000"
                >
                  <div className=" bg-[#EEEDFF] text-blue-violet-500 font-semibold text-2xl px-8 py-3 rounded-full inline-block">
                    {data && data?.shortDescription}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{ __html: data && data?.title }}
                    className="2xl:text-[45px] xl:text-3xl lg:text-xl md:text-md text-xl font-bold flex flex-col gap-0 2xl:leading-tight uppercase "
                  >
                    {/* Grand Passenger
                    <span className="text-blue-violet-500 italic">
                      Ride Share
                    </span> */}
                  </div>

                  <div className="relative lg:mt-3 w-full">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data && data?.description,
                      }}
                      className="text-slate-400 xl:text-base xl:leading-8  xl:mt-6  leading-10  mt-2 md:mt-0"
                    ></div>
                  </div>
                  <div className="flex gap-3 items-center mt-4 md:mt-10 ">
                    {data && (
                      <AppCallToAction
                        data={{
                          buttonText1: data?.buttonText1,
                          buttonLink1: data?.buttonLink1,
                          buttonText2: data?.buttonText2,
                          buttonLink2: data?.buttonLink2,
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      ) : (
        <>
          <BannerLoader />
        </>
      )}
    </>
  );
};

export default RideShareBanner;
