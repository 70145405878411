import React, { useEffect, useState } from "react";
import LogoAlt from "../assets/images/logo(alt) 1.png";
import { Link } from "react-router-dom";
import Button from "../components/form/Button";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setSiteSettingData } from "../store/slice/siteSettingSlice";
import BASE_URL from "../config/host";
import { ToastContainer, toast } from "react-toastify";
import { setPages } from "../store/slice/pageSlice";
import "react-toastify/dist/ReactToastify.css";

const DefaultFooter = ({ data }) => {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(true);
  const settingsData = useSelector((state) => state.siteSettings);
  const [email, setEmail] = useState("");
  let emailCheaker =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const fetchSiteSettingData = async () => {
    setLoaded(false);
    try {
      const response = await axios.get(`${BASE_URL}/site-setting`);
      setLoaded(true);
      dispatch(setSiteSettingData(response?.data.data));
    } catch (error) {
      setLoaded(true);
      console.log(error, "error");
    }
  };
  const fetchPageData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/pages`);

      dispatch(setPages(response?.data?.docs));
    } catch (error) {
      console.log(error, "error");
    }
  };
  useEffect(() => {
    fetchSiteSettingData();
    fetchPageData();
  }, []);

  const handleContact = () => {
    // !email && toast.info("Enter your email id!");
    email && emailCheaker.test(String(email).toLowerCase())
      ? axios
          .post(`${BASE_URL}/enquiry/contact`, {
            enquiryType: "notification",
            email: email,
          })
          .then((res) => {
            toast.success(res?.data?.message);
            setEmail("");
          })
      : toast.info("Please enter a valid email!");
  };

  return (
    <>
      <footer className="relative bg-black pt-8 ">
        <div className="relative py-16 w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
          <div className="flex flex-col lg:flex-row gap-10 lg:gap-5">
            <div className="w-full sm:w-4/5 mx-auto lg:mx-0 lg:w-4/12 pr-4">
              <div className="xl:w-40 md:w-32 w-24 overflow-hidden">
                <img
                  src={settingsData && settingsData?.data?.logo?.url}
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="2xl:text-lg xl:text-base leading-6 text-sm  text-gray-200 mt-4 pr-15">
                {settingsData && settingsData?.data?.description}
              </div>
            </div>
            <div className="w-full lg:w-7/12">
              <div className="grid md:grid-cols-2 sm:flex justify-between gap-y-10 sm:gap-y-0 gap-x-2 sm:gap-x-5">
                <div className="w-full sm:w-1/4">
                  <h4 className="text-base 2xl:text-lg xl:text-base md:text-xs font-semibold text-white uppercase mb-6">
                    Sitemap
                  </h4>
                  <div className="relative space-y-3 sm:space-y-4">
                    <div className="flex">
                      <Link
                        to={"/"}
                        className="text-slate-200 text-xs xl:text-sm transition-all duration-200 hover:text-carnation-500 hover:ml-2"
                      >
                        Home
                      </Link>
                    </div>
                    <div className="flex">
                      <Link
                        to="/healthcareparatransit"
                        className="text-slate-200 text-xs xl:text-sm transition-all duration-200 hover:text-carnation-500 hover:ml-2"
                      >
                        Ride
                      </Link>
                    </div>
                    <div className="flex">
                      <Link
                        to="/drive"
                        className="text-slate-200 text-xs xl:text-sm transition-all duration-200 hover:text-carnation-500 hover:ml-2"
                      >
                        Drive
                      </Link>
                    </div>
                    <div className="flex">
                      <Link
                        to="/contact"
                        className="text-slate-200 text-xs xl:text-sm transition-all duration-200 hover:text-carnation-500 hover:ml-2"
                      >
                        Contact
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="w-full sm:w-1/4">
                  <h4 className="text-base 2xl:text-lg xl:text-base md:text-xs font-semibold text-white uppercase mb-6">
                    Contact Info
                  </h4>
                  <div className="relative space-y-3 sm:space-y-4">
                    <div className="flex items-center gap-4">
                      <div className="text-slate-200 xl:text-sm">
                        <i className="fa-solid fa-phone"></i>
                      </div>
                      <Link className="text-slate-200 text-xs xl:text-sm transition-all duration-200 hover:text-carnation-500 hover:ml-2">
                        {setSiteSettingData && settingsData?.data?.phone}
                      </Link>
                    </div>
                    <div className="flex items-center gap-4">
                      <div className="text-slate-200 xl:text-sm">
                        <i className="fa-solid fa-envelope"></i>
                      </div>
                      <Link className="text-slate-200 text-xs xl:text-sm transition-all duration-200 hover:text-carnation-500 hover:ml-2">
                        {setSiteSettingData && settingsData?.data?.email}
                      </Link>
                    </div>
                    <div className="flex items-center gap-4">
                      <div className="text-slate-200 xl:text-sm">
                        <i className="fa-solid fa-location-dot"></i>
                      </div>
                      <Link className="text-slate-200 text-xs xl:text-sm transition-all duration-200 hover:text-carnation-500 hover:ml-2">
                        {setSiteSettingData &&
                          settingsData?.data?.address?.address1}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-1/4">
                  <h4 className="text-base 2xl:text-lg xl:text-base md:text-xs font-semibold text-white uppercase mb-6">
                    Get Notifications
                  </h4>
                  <div className="relative space-y-3 sm:space-y-4 w-full">
                    <div className="flex flex-col w-full">
                      <input
                        type="email"
                        className="w-full rounded-full px-4 h-12"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <div className="relative mt-4 !w-full  ">
                        <Button
                          className="bg-blue-violet-500 text-center !rounded-full w-full " 
                          buttonFunction={handleContact}
                          buttonLabel="Submit"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#1B1A1A]">
          <div
            className="relative text-xs text-zinc-500 py-5 w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto 
           text-center"
          >
            © Grand 2024 all right reserve here
          </div>
        </div>
      </footer>
      <ToastContainer />
    </>
  );
};

export default DefaultFooter;
