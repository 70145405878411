import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";

import Home from "./pages/Home";
import DefaultLayout from "./layouts/DefaultLayout";
import Contact from "./pages/Contact";
import Drive from "./pages/Drive";
import Ride from "./pages/Ride";
import HealthcareParatransit from "./pages/HealthcareParatransit";
import ElitLuxuryConcierge from "./pages/ElitLuxuryConcierge";
import ElectricRide from "./pages/ElectricRide";
import RideShare from "./pages/RideShare";

// import { Router } from "react-router-dom/dist/umd/react-router-dom.development";

const CreateRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DefaultLayout />}>
          <Route path="/" exact element={<Home />} />
        </Route>
        <Route path="/contact" element={<DefaultLayout />}>
          <Route path="/contact" exact element={<Contact />} />
        </Route>
        <Route path="/drive" element={<DefaultLayout />}>
          <Route path="/drive" exact element={<Drive />} />
        </Route>
        <Route path="/ride" element={<DefaultLayout />}>
          <Route path="/ride" exact element={<Ride />} />
        </Route>
        <Route path="/healthcareparatransit" element={<DefaultLayout />}>
          <Route path="/healthcareparatransit" exact element={<HealthcareParatransit />} />
        </Route>
        <Route path="/elitluxuryconcierge" element={<DefaultLayout />}>
          <Route path="/elitluxuryconcierge" exact element={<ElitLuxuryConcierge />} />
        </Route>
        <Route path="/electricride" element={<DefaultLayout />}>
          <Route path="/electricride" exact element={<ElectricRide />} />
        </Route>
        <Route path="/rideshare" element={<DefaultLayout />}>
          <Route path="/rideshare" exact element={<RideShare/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default CreateRoutes;
