import React, { useState, useEffect } from "react";
import RideShareBanner from "../components/sections/RideShare/RideShareBanner";
import ContactUs from "../components/sections/Common/ContactUs/ContactUs";
import HowtoWork from "../components/sections/RideShare/HowToWork";
import OnlineBook from "../assets/images/image29.jpg";
import FindMatches from "../assets/images/image30.jpg";
import ShareTheRide from "../assets/images/image31.jpg";
import SplitTheCost from "../assets/images/image32.jpg";
import Benefits from "../components/sections/RideShare/BenefitsOfRide";
import SafetyAndSecurity from "../components/sections/RideShare/SafetyAndSecurity";
import { useSelector } from "react-redux";
import axios from "axios";
import BASE_URL from "../config/host";

const RideShare = () => {
  const siteSettingData = useSelector((state) => state?.siteSettings?.data);
  const [allPageData, setAllPageData] = useState([]);
  const [loaded, setLoaded] = useState(true);

  let fetchPages = async () => {
    setLoaded(false);
    try {
      let data = await axios.get(`${BASE_URL}/pages`, {
        params: {
          limit: 100,
        },
      });
      if (data.status === 200) {
        setAllPageData(data?.data?.docs);
        setLoaded(true);
      }
    } catch (error) {
      setLoaded(true);
      console.log(error, "page error");
    }
  };
  useEffect(() => {
    fetchPages();
  }, []);

  const contactDeatail = {
    title: "Contact Us",
    subTitle:
      " Ready to experience the height of luxury and sophistication with Grand Elite Luxury Transportation? Contact us today to book your next journey.",
    btn: "Contact Us",
    heading:
      " Experience the epitome of luxury travel with Grand Elite Luxury Transportation. Let us redefine your expectations and elevate your journey to new heights of comfort and style",
    icon1: "fa-solid fa-phone",
    number: "718-433-4255",
    icon2: "fa-solid fa-envelope",
    email: "luxury@rydegrand.com",
    icon3: "fa-solid fa-location-dot",
    address: "36-13 32nd St LIC NY 11106",
  };

  const ourService = [
    {
      _id: 1,
      image: OnlineBook,
      heading: "Request a Ride",
      headingAlt: "Ride",
      description:
        "Simply enter your pickup location, destination, and the number of passengers in your group using our user-friendly app or website",
    },
    {
      _id: 2,
      image: FindMatches,
      heading: "Find",
      headingAlt: "Matches",
      description:
        "Our advanced matching algorithm will pair you with nearby passengers traveling in the same direction, optimizing routes and minimizing detours for a faster and more efficient journey",
    },
    {
      _id: 3,
      image: ShareTheRide,
      heading: "Share the",
      headingAlt: "Ride",
      description:
        "Once matched, you'll receive details about your fellow passengers and the estimated pickup time. Meet your driver and coriders at the designated location, and enjoy a comfortable ride together.",
    },
    {
      _id: 4,
      image: SplitTheCost,
      heading: "Split the",
      headingAlt: "Cost",
      description:
        "With Grand Passenger Ride Share, you'll only pay for your portion of the trip, making shared transportation an affordable and costeffective option for your daily commute or occasional travels",
    },
  ];

  const BenefitsOfRideDetails = [
    {
      _id: 1,
      icon: "fa-solid fa-piggy-bank",
      heading: "Cost Savings",
      description:
        "Share the cost of transportation with fellow passengers and enjoy significant savings compared to traditional solo rides.",
    },
    {
      _id: 2,
      icon: "fa-solid fa-shoe-prints",
      heading: "Reduced Carbon Footprint",
      description:
        " By sharing rides, you're contributing to a greener environment by reducing the number of vehicles on the road and lowering carbon emissions.",
    },
    {
      _id: 3,
      icon: "fa-solid fa-users",
      heading: "Social Connectivity",
      description:
        " Connect with like-minded individuals and build community bonds while traveling to your destination together.",
    },
    {
      _id: 4,
      icon: "fa-solid fa-car",
      heading: "Efficient Transportation",
      description:
        " Our ride-sharing platform optimizes routes and minimizes detours, resulting in shorter travel times and a more efficient journey for everyone involved.",
    },
  ];

  const SafetyAndSecurityDetails = [
    {
      _id: 1,
      icon: "fa-solid fa-id-card",
      heading: "Background-checked Drivers",
      description:
        "Rest assured that all drivers on our platform undergo thorough background checks to ensure your safety and security.",
    },
    {
      _id: 2,
      icon: "fa-solid fa-shield-check",
      heading: "Secure Payment System",
      description:
        "Your payment information is encrypted and securely processed, providing peace of mind during transactions.",
    },
    {
      _id: 3,
      icon: "fa-solid fa-clock-one",
      heading: "24/7 Support",
      description:
        "Our dedicated customer support team is available around the clock to assist you with any questions or concerns you may have during your ride-sharing experience.",
    },
  ];

  return (
    <>
      <RideShareBanner
        data={
          allPageData &&
          allPageData.find((elm) => elm?.type === "passenger-ride-share1")
        }
        loaded={loaded}
      />
      <Benefits
        data={
          allPageData &&
          allPageData.find((elm) => elm?.type === "passenger-ride-share2")
        }
        loaded={loaded}
      />
      <HowtoWork
        // data={ourService}
        data={
          allPageData &&
          allPageData.find((elm) => elm?.type === "passenger-ride-share3")
        }
        loaded={loaded}
      />
      <SafetyAndSecurity
        data={
          allPageData &&
          allPageData.find((elm) => elm?.type === "passenger-ride-share4")
        }
        loaded={loaded}
      />
      <ContactUs
        data={contactDeatail}
        data1={siteSettingData}
        loaded={loaded}
      />
    </>
  );
};

export default RideShare;
