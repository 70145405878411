import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: {},
};

export const siteSettingSlice = createSlice({
  name: "siteSettings",
  initialState,
  reducers: {
    setSiteSettingData: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { setSiteSettingData } = siteSettingSlice.actions;
export default siteSettingSlice.reducer;
