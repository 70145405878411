import { combineReducers } from "redux";
import siteSettings from "./slice/siteSettingSlice";
import pageSlice from "./slice/pageSlice";

const rootReducer = (asyncRedicer) => (state, action) => {
  const combinedReducers = combineReducers({
    siteSettings,
    pageSlice,
    ...asyncRedicer,
  });
  return combinedReducers(state, action);
};

export default rootReducer;
