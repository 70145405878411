import React, { useState, useEffect } from "react";
import ElectricRideBanner from "../components/sections/ElectricRide/ElectricRideBanner";
import ElectricFleet from "../components/sections/ElectricRide/ElectricFleet";

import Electric from "../assets/images/image23.jpg";
import Suv from "../assets/images/image28.jpg";
import ChooseElectricRide from "../components/sections/ElectricRide/ChooseGrand";
import BookCar from "../components/sections/Common/BookCar/BookCar";
import ContactUs from "../components/sections/Common/ContactUs/ContactUs";
import { useSelector } from "react-redux";
import axios from "axios";
import BASE_URL from "../config/host";

const ElectricRide = () => {
  const siteSettingData = useSelector((state) => state?.siteSettings?.data);
  const [allPageData, setAllPageData] = useState([]);
  const [loaded, setLoaded] = useState(true);

  const ElectricFleetData = [
    {
      _id: 1,
      image: Electric,
      heading: "Electric",
      headingAlt: "Sedans",
      description:
        "Experience quiet and emissions-free travel with our fleet of electric sedans. Perfect for individuals or small groups, our electric sedans combine eco-friendliness with luxury and comfort.",
    },
    {
      _id: 2,
      image: Suv,
      heading: "Electric",
      headingAlt: "SUVs",
      description:
        "Travel in spacious comfort with our electric SUVs, ideal for families or larger groups. With zero emissions and advanced technology, our electric SUVs provide a sustainable transportation solution without sacrificing style or convenience.",
    },
  ];

  const ChooseElectric = [
    {
      _id: 1,
      icon: "fa-solid fa-seedling",
      headline: "Environmental Sustainability",
      description:
        "Reduce your carbon footprint and contribute to a cleaner, greener future with our electric transportation solutions. By choosing Grand Electric Ride, you're making a positive impact on the environment and supporting sustainable transportation practices",
    },
    {
      _id: 2,
      icon: "fa-solid fa-microchip",
      headline: "Cutting-Edge Technology",
      description:
        "Our electric vehicles feature state-of-the-art technology and innovative design, providing passengers with a comfortable and enjoyable ride while minimizing environmental impact.",
    },
    {
      _id: 3,
      icon: "fa-solid fa-circle-sterling",
      headline: "Cost-Effective",
      description:
        "Electric vehicles offer lower operating costs compared to traditional gasoline-powered vehicles, making them a cost-effective transportation solution for both individuals and businesses.",
    },
    {
      _id: 4,
      icon: "fa-solid fa-car",
      headline: "Zero Emissions",
      description:
        "With zero tailpipe emissions, our electric vehicles help improve air quality and reduce greenhouse gas emissions, creating a healthier environment for everyone.",
    },
  ];

  const BookCarDetails = {
    title: "How To",
    titleAlt: "Book",
    subtitle:
      "Booking your eco-friendly ride with Grand Electric Ride is simple:",

    subdata: [
      {
        _id: 1,
        icon: "fa-light fa-globe",
        heading: "Online Reservation:",
        description:
          "Visit our website to book your electric ride conveniently online. Select your preferred vehicle, enter your pickup and drop-off locations, and choose your desired time and date.",
      },
      {
        _id: 2,
        icon: "fa-solid fa-phone",
        heading: "Phone Reservation",
        description:
          "Prefer to speak with a representative? Give us a call, and our friendly staff will assist you in scheduling your electric transportation needs.",
      },
      {
        _id: 3,
        icon: "fa-solid fa-mobile",
        heading: " Mobile App",
        description:
          "Download our mobile app for quick and easy booking on the go. Access real-time availability, track your ride, and manage your reservations with ease..",
      },
    ],
  };

  const contactDeatail = {
    title: "Contact Us",
    subTitle:
      "Ready to ride sustainably with Grand Electric Ride? Contact us today to book your eco-friendly transportation.",
    btn: "Contact Us",
    heading:
      " Join us in embracing eco-friendly transportation practices with Grand Electric Ride. Experience the convenience, comfort, and sustainability of electric vehicles on your next journey.",
    icon1: "fa-solid fa-phone",
    number: "718-433-4255",
    icon2: "fa-solid fa-envelope",
    email: "ev@rydegrand.com",
    icon3: "fa-solid fa-location-dot",
    address: "36-13 32nd St LIC NY 11106",
  };

  let fetchPages = async () => {
    setLoaded(false);
    try {
      let data = await axios.get(`${BASE_URL}/pages`, {
        params: {
          limit: 100,
        },
      });
      if (data.status === 200) {
        setAllPageData(data?.data?.docs);
        setLoaded(true);
      }
    } catch (error) {
      setLoaded(true);
      console.log(error, "page error");
    }
  };
  useEffect(() => {
    fetchPages();
  }, []);

  return (
    <>
      <ElectricRideBanner
        data={
          allPageData &&
          allPageData.find((elm) => elm?.type === "electric-ride1")
        }
        loaded={loaded}
      />
      <ElectricFleet
        // data={ElectricFleetData}
        data={
          allPageData &&
          allPageData.find((elm) => elm?.type === "electric-ride2")
        }
        loaded={loaded}
      />
      <ChooseElectricRide
        // data={ChooseElectric}
        data={
          allPageData &&
          allPageData.find((elm) => elm?.type === "electric-ride3")
        }
        loaded={loaded}
      />
      <BookCar
        // data={BookCarDetails}
        data={
          allPageData &&
          allPageData.find((elm) => elm?.type === "electric-ride4")
        }
        loaded={loaded}
      />
      <ContactUs
        data={contactDeatail}
        data1={siteSettingData}
        loaded={loaded}
      />
    </>
  );
};

export default ElectricRide;
