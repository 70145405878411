import React from "react";
import { Link } from "react-router-dom";
import TwoSectionLoader from "../../../Elements/SkeletonLoader/TwoSectionLoader";

const ContactUs = ({ data, data1, loaded }) => {
  return (
    <>
      {loaded ? (
        <div className="relative bg-[#F6F6F6] pl-36 mt-14" 
            data-aos="fade-down"
            data-aos-duration="3000">
          <div className="relative flex w-full items-center">
            <div className="relative py-12 w-full pr-10 h-full ">
              <div className="text-4xl font-bold text-black mb-4">
                {data?.title}
              </div>
              <div className="relative mb-10 pr-10 text-slate-600 text-2xl leading-10">
                {data?.subTitle}
              </div>
              <Link
                to={"/contact"}
                className="bg-blue-violet-500 rounded-full px-6 py-3 text-white"
              >
                {data?.btn}
              </Link>
            </div>
            <div className="relative bg-white  py-8 pl-16 pr-36 w-full border-l border-zinc-200">
              <div className="flex flex-col">
                <div className="text-xl font-normal text-slate-600 pr-10">
                  {data1?.description}
                </div>
                <div className="flex flex-col gap-4 mt-4">
                  <div className="flex items-center gap-3 bg-white shadow-lg rounded-lg py-4 px-6">
                    <div className="w-10 h-10 rounded-full bg-slate-200 flex items-center justify-center text-sm text-blue-violet-500 shrink-0">
                      <i className={data?.icon1}></i>
                    </div>
                    <div className="shrink text-xl text-black font-medium">
                      {data1?.phone}
                    </div>
                  </div>
                  <div className="flex items-center gap-3 bg-white shadow-lg rounded-lg py-4 px-6">
                    <div className="w-10 h-10 rounded-full bg-slate-200 flex items-center justify-center text-sm text-blue-violet-500 shrink-0">
                      <i className={data?.icon2}></i>
                    </div>
                    <div className="shrink text-xl text-black font-medium">
                      {data1?.email}
                    </div>
                  </div>
                  <div className="flex items-center gap-3 bg-white shadow-lg rounded-lg py-4 px-6">
                    <div className="w-10 h-10 rounded-full bg-slate-200 flex items-center justify-center text-sm text-blue-violet-500 shrink-0">
                      <i className={data?.icon3}></i>
                    </div>
                    <div className="shrink text-xl text-black font-medium">
                      {data1?.address?.address1}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <TwoSectionLoader />
      )}
    </>
  );
};

export default ContactUs;
