import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: {},
};

export const pageSlice = createSlice({
  name: "pages",
  initialState,
  reducers: {
    setPages: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { setPages } = pageSlice.actions;
export default pageSlice.reducer;
