import React from "react";
import { classNames } from "../../../helpers/classNames";
import TwoSectionLoader from "../../Elements/SkeletonLoader/TwoSectionLoader";

const HowitWork = ({ data, loaded }) => {
  return (
    <>
      {loaded ? (
        <div className="relative pt:py-8 md:py-14">
          <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex flex-col-reverse  md:flex-row items-center md:h-full my-0 md:my-auto ">
            <div className="relative">
              <div
                dangerouslySetInnerHTML={{ __html: data && data?.title }}
                className="text-black text-4xl font-bold text-center mb-12"
              >
                {/* How It
            <span className="text-blue-violet-500 italic ml-2">Works</span> */}
              </div>
              <div className="relative space-y-14">
                {data &&
                  data?.steps.map((item, index) => (
                    <>
                      <div
                        key={index}
                        className={classNames(
                          "relative flex flex-row items-center justify-between gap-14",
                          index % 2 === 1 ? "flex-row-reverse" : ""
                        )}
                        data-aos={index % 2 === 1 ? "fade-right" : "fade-left"}
                      >
                        <div className="relative w-1/2">
                          <div className="w-full aspect-[2/1.2] overflow-hidden rounded-4xl">
                            <img
                              src={item?.stepsImage?.url}
                              alt=""
                              className="w-full h-full object-cover"
                            />
                          </div>
                        </div>
                        <div className="relative w-1/2 ">
                          <div className="text-black text-4xl font-bold flex gap-2 md:leading-tight">
                            {item?.heading}
                            {/* <span className=" !text-blue-violet-500 italic">
                      {item.headingAlt}
                    </span> */}
                          </div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.description,
                            }}
                            className="text-base leading-7 mt-4 text-slate-600"
                          ></div>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <TwoSectionLoader />
      )}
    </>
  );
};

export default HowitWork;
